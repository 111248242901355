const FullPageLoader = () => {
    return(
        <main className="w-full h-full relative flex justify-center items-center overflow-y-auto ml-0 md:ml-[25%] xl:ml-[16.666667%] pt-20 md:pt-24 px-4 md:px-20 space-y-10">
            <div className="max-w-lg w-40 py-6 bg-white rounded flex justify-center items-center animate-pulse">
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg"  style={{marginRight:"5px", background: "none", display: "block", shapeRendering: "auto", width:"50px", height:"50px"}} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                        <circle cx="50" cy="50" r="32" stroke-width="8" stroke='#00ACE6' stroke-dasharray="50.26548245743669 50.26548245743669" fill="none" stroke-linecap="round">
                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
                        </circle>
                    </svg>
                </span>
            </div>
        </main>
    )
}

export default FullPageLoader