const ToasterItem = ({ type, text, id, remove, }: { type: string; text: string, id:any, remove:(id:any)=>void}) => {

  return (
      <div
        className={`mt-4 w-full md:w-1/2 lg:w-2/5 xl:w-1/4 py-4 lg:py-5 bg-white shadow-drop-down-shadow flex justify-between rounded-b items-center px-4 lg:px-6 border-t-4 ${
          type === "success"
            ? "border-[#0BB251]"
            : type === "warning"
            ? "border-[#F8B301]"
            : "border-[#FF0F0F]"
        }`}
      >
        <div className="flex space-x-6 items-center">
          <span>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {type === "success" ? (
                <path
                  d="M12.9784 6.14424C13.2531 6.4189 13.2531 6.86412 12.9784 7.13864L8.26144 11.8558C7.98679 12.1303 7.5417 12.1303 7.26704 11.8558L5.02158 9.61015C4.74692 9.33563 4.74692 8.89041 5.02158 8.61589C5.2961 8.34123 5.74132 8.34123 6.01584 8.61589L7.76418 10.3642L11.984 6.14424C12.2587 5.86972 12.7039 5.86972 12.9784 6.14424ZM18 9C18 13.9747 13.9741 18 9 18C4.02525 18 0 13.9741 0 9C0 4.02525 4.02594 0 9 0C13.9747 0 18 4.02594 18 9ZM16.5938 9C16.5938 4.80254 13.1969 1.40625 9 1.40625C4.80254 1.40625 1.40625 4.80309 1.40625 9C1.40625 13.1975 4.80309 16.5938 9 16.5938C13.1975 16.5938 16.5938 13.1969 16.5938 9Z"
                  fill="#0EBA56"
                />
              ) : type === "warning" ? (
                <>
                  <g clip-path="url(#clip0)">
                    <path
                      d="M9.63972 11.2435C9.1504 11.2435 8.74023 11.6537 8.74023 12.143C8.74023 12.6323 9.1504 13.0425 9.63972 13.0425C10.111 13.0425 10.5392 12.6323 10.5176 12.1646C10.5392 11.6501 10.1326 11.2435 9.63972 11.2435Z"
                      fill="#FAB503"
                    />
                    <path
                      d="M17.9654 14.4313C18.5302 13.4563 18.5338 12.2941 17.9726 11.3227L12.3382 1.5651C11.7805 0.582865 10.7731 0 9.64335 0C8.5136 0 7.50618 0.586463 6.9485 1.5615L1.30695 11.3299C0.745671 12.3121 0.749269 13.4814 1.31774 14.4565C1.87902 15.4207 2.88284 16 4.0054 16H15.2597C16.3859 16 17.3969 15.4135 17.9654 14.4313ZM16.7421 13.7261C16.4291 14.2658 15.875 14.586 15.2561 14.586H4.0018C3.39015 14.586 2.83967 14.273 2.53384 13.7441C2.22442 13.208 2.22082 12.5676 2.53025 12.0279L8.1718 2.2631C8.47763 1.72701 9.02451 1.41039 9.64335 1.41039C10.2586 1.41039 10.8091 1.73061 11.1149 2.2667L16.7529 12.0315C17.0551 12.5568 17.0515 13.19 16.7421 13.7261Z"
                      fill="#FAB503"
                    />
                    <path
                      d="M9.41657 4.92917C8.98841 5.0515 8.72217 5.44008 8.72217 5.91141C8.74376 6.19564 8.76175 6.48348 8.78333 6.76771C8.8445 7.85069 8.90566 8.91208 8.96683 9.99506C8.98841 10.362 9.27265 10.6283 9.63964 10.6283C10.0066 10.6283 10.2945 10.3441 10.3125 9.97347C10.3125 9.7504 10.3125 9.54532 10.334 9.31865C10.3736 8.62425 10.4168 7.92985 10.4564 7.23545C10.478 6.7857 10.5175 6.33596 10.5391 5.88622C10.5391 5.72431 10.5175 5.5804 10.4564 5.43648C10.2729 5.03351 9.84472 4.82843 9.41657 4.92917Z"
                      fill="#FAB503"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="18.6667" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </>
              ) : (
                <>
                  <path
                    d="M9 0C4.02567 0 0 4.02528 0 9C0 13.9744 4.02528 18 9 18C13.9743 18 18 13.9747 18 9C18 4.02564 13.9747 0 9 0ZM9 16.5938C4.80291 16.5938 1.40625 13.1974 1.40625 9C1.40625 4.80287 4.80259 1.40625 9 1.40625C13.1971 1.40625 16.5938 4.80259 16.5938 9C16.5938 13.1971 13.1974 16.5938 9 16.5938Z"
                    fill="#FF0F0F"
                  />
                  <path
                    d="M12.0792 11.0848L9.99436 8.99999L12.0792 6.91516C12.3538 6.64059 12.3538 6.1954 12.0792 5.9208C11.8046 5.64619 11.3594 5.64623 11.0849 5.9208L9 8.00563L6.91514 5.9208C6.6406 5.64619 6.19535 5.64619 5.92078 5.9208C5.64621 6.1954 5.64621 6.64059 5.92082 6.91516L8.00565 8.99999L5.92082 11.0848C5.64621 11.3594 5.64617 11.8046 5.92078 12.0792C6.19545 12.3538 6.64064 12.3537 6.91514 12.0792L9 9.99435L11.0849 12.0792C11.3594 12.3538 11.8046 12.3538 12.0792 12.0792C12.3538 11.8046 12.3538 11.3594 12.0792 11.0848Z"
                    fill="#FF0F0F"
                  />
                </>
              )}
            </svg>
          </span>
          <p className="text-sm">{text}</p>
        </div>
        <button onClick={()=>remove(id)}>
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 4.66688L10.6669 0L12 1.33312L7.33312 6L12 10.6669L10.6669 12L6 7.33312L1.33312 12L0 10.6669L4.66688 6L0 1.33312L1.33312 0L6 4.66688Z"
              fill="#101010"
            />
          </svg>
        </button>
    </div>
  );
};

export default ToasterItem;
