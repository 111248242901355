import React from "react";
import { IActionUtil } from "../utils/AsyncUtils";
import { mapProperty2Key, toast } from "../utils/helper";

const getErrorMessage = (error: any) => {
  if (Array.isArray(error)) {
    if (error.length > 1) {
      return error[1];
    }
    return error[0];
  }
  return error;
};

export const dispatchError = (
  dispatch: React.Dispatch<any>,
  fn: (action: string) => IActionUtil,
  error: any,
  status: number,
  actionType: string,
  showToast?: boolean,
  useErrorKeys?: boolean
) => {
  if (status >= 500) {
    const message = "Internal server error";
    dispatch(fn(actionType).loading(false));
    showToast && toast(dispatch, { type: "error", text: message });
    return dispatch(fn(actionType).failure(true, message));
  } else {
    const { message, errors } = error.response.data;
    if (errors && Object.keys(errors).length > 0) {
      const keys = Object.keys(errors);
      let e: any;
      if (useErrorKeys) {
        let err = {};
        keys.forEach((key) => {
          err = {
            ...err,
            [mapProperty2Key(key as string)]: getErrorMessage(errors[key]),
          };
        });
        e = err;
      } else {
        const errs: string[] = [];
        keys.forEach((key) => {
          errs.push(errors[key][0]);
        });
        showToast &&
          errs.forEach((e) => {
            toast(dispatch, { type: "error", text: e });
          });
        e = errs;
      }
      dispatch(fn(actionType).failure(true, e));
    } else {
      showToast && toast(dispatch, { type: "error", text: message });
      dispatch(fn(actionType).failure(true, message));
    }
  }
  dispatch(fn(actionType).loading(false));
};

export const statelessErrorHandler = (
  onError: (err: string | string[]) => void,
  e: any,
  useErrorKeys?: boolean
) => {
  if (onError) {
    const status = e.response.status;
    if (status >= 500) {
      const message = "Internal server error";
      return onError(message);
    }
    const { message, errors } = e.response.data;
    if (errors && Object.keys(errors).length > 0) {
      const keys = Object.keys(errors);
      let e: any;
      if (useErrorKeys) {
        let err = {};
        keys.forEach((key) => {
          err = {
            ...err,
            [mapProperty2Key(key as string)]: getErrorMessage(errors[key]),
          };
        });
        e = err;
      } else {
        const errs: string[] = [];
        keys.forEach((key) => {
          errs.push(errors[key][0]);
        });
        e = errs;
      }
      return onError(e);
    }
    return onError(message);
  }
};
