import { useContext } from "react";
import { useState } from "react";
import { removeOrganizationMember } from "../../../Requests/OrganizationRequest";
import { getActiveOrganizationId } from "../../../utils/helper";
import { Context } from "../../../utils/store";
import TeamMembersLoader from "../../Loader/TeamMembersLoader";
import InviteTeamMember from "../../modal/InviteTeam";
import RemoveTeamMemberModal from "../../modal/RemoveTeamMember";

const TeamMembers = () => {
  const { state, dispatch } = useContext(Context);
const organizationId= getActiveOrganizationId() as string;
  const [isOpen, setIsopen] = useState(false);
  const [isOpenDeleteDialog, setIsopenDeleteDialog] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState<null | string>(
    null
  );
  const toggleModal = () => {
    setIsopen(!isOpen);
  };
  const openDeleteDialog = (email: string) => {
    setSelectedTeamMember(email);
    setIsopenDeleteDialog(true);
  };
  const closeDeleteDialog = () => {
    setSelectedTeamMember(null);
    setIsopenDeleteDialog(false);
  };

  const removeUser = async () => {
    removeOrganizationMember(dispatch,organizationId, selectedTeamMember as string, closeDeleteDialog, closeDeleteDialog)
  };

  const _formatRole = (role: string) => {
    return role.replace("-", " ");
  };
  return (
    <>
      {isOpen && <InviteTeamMember toggleModal={toggleModal} />}
      {isOpenDeleteDialog && (
        <RemoveTeamMemberModal
          removeUser={removeUser}
          closeDeleteDialog={closeDeleteDialog}
        />
      )}
      <div className="flex justify-between items-center">
        <h3 className="text-sm font-medium text-black">Team members</h3>
        <button className="text-sm font-medium text-blue" onClick={toggleModal}>
          +Invite team mate
        </button>
      </div>
      <div className="w-full bg-white px-2 md:px-6 py-2 rounded border border-[#D1D1D1] overflow-x-auto scrolling-wrapper">
        <table className="w-full text-left">
          <thead>
            <tr className="">
              <th className="py-4 text-sm text-hair">Email</th>
              <th className="py-4 text-sm text-hair">Role</th>
              <th className="py-4 text-sm text-hair text-right">Options</th>
            </tr>
          </thead>
          <tbody>
            {state?.Organization.loadingTeams && (
              <tr className="space-x-2">
                <td className="py-4 text-sm text-black">
                  <TeamMembersLoader count={3}/>
                </td>
                <td className="py-4 text-sm text-black whitespace-nowrap">
                  <TeamMembersLoader count={3} />
                </td>
                <td className="py-4 flex space-x-3 items-center justify-end">
                <TeamMembersLoader count={3}/>
                </td>
              </tr>
            )}
            {!state?.Organization.loadingTeams &&
              state?.Organization.team &&
              state?.Organization.team.length > 0 &&
              state?.Organization.team.map((tm, idx) => {
                return (
                  <tr key={idx} className="space-x-2">
                    <td className="py-4 text-sm text-black">{tm.email}</td>
                    <td className="py-4 text-sm text-black whitespace-nowrap">
                      {tm.role && _formatRole(tm.role)}
                    </td>
                    <td className="py-4 flex space-x-3 items-center justify-end">
                      <button onClick={() => openDeleteDialog(tm.email)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 text-black"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg>
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        {!state?.Organization.loadingTeams &&
          state?.Organization.team &&
          state?.Organization.team.length === 0 && (
            <div className="w-full py-32 flex flex-col items-center justify-center space-y-1">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 text-black"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
              </span>
              <p className="text-black text-sm">No team members</p>
            </div>
          )}
      </div>
    </>
  );
};

export default TeamMembers;
