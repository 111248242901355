import TextInput from "../../components/input/TextInput";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "../../components/Button";
import { IFormValues } from "../../utils/interfaces";
import { useContext } from "react";
import { Context } from "../../utils/store";
import { AuthError } from "../../utils/ErrorHandler";
import { resetPassword } from "../../Requests/AuthRequest";
import { useState } from "react";
import { AlertMeesage } from "../Alert/alert";
import { _displayValidation } from "../../utils/helper";
import { State } from "../../utils/store/initialState";

const schema = yup.object().shape({
  token: yup.string().required(),
  password: yup.string().required(),
});

const ResetPassword = ({ callback }: { callback?: () => void }) => {
  const { state, dispatch } = useContext(Context);
  const [success, setSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: yupResolver(schema),
  });
  const _onDone = () => {
    setSuccess(true);
  };

  const onSubmit: SubmitHandler<IFormValues> = (data) => {
    resetPassword(dispatch, data, _onDone);
  };


  return (
    <div className="h-full w-full max-w-md mx-auto space-y-6 mt-20 md:mt-52">
      <h2 className="text-2xl font-bold text-black">Change Password</h2>
      {state?.Auth.resetPasswordError &&
        state.Auth.resetPasswordError.status && (typeof state.Auth.resetPasswordError.error =="string" || Array.isArray(state?.Auth.resetPasswordError?.error))&&(
          <AuthError error={state?.Auth.resetPasswordError?.error} />
        )}
      {success && (
        <AlertMeesage type="success" message="Password successfully changed" />
      )}
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full relative">
          <TextInput
            first={true}
            error={errors?.token?.message || _displayValidation("token", state as State, "resetPasswordError" as any, "Auth" as any)}
            second={false}
            last={false}
            id="token"
            register={register}
            label="token"
            name="token"
            type="token"
            required={true}
            placeholder="Enter Token"
          />
        </div>
        <div className="w-full relative">
          <TextInput
            first={false}
            error={errors?.password?.message || _displayValidation("password", state as State, "resetPasswordError" as any, "Auth" as any)}
            second={false}
            last={true}
            id="password"
            register={register}
            label="password"
            name="password"
            type="password"
            required={true}
            placeholder="New Password"
          />
        </div>
        <div className="mt-10">
          <Button
            text="Change password"
            onClick={handleSubmit(onSubmit)}
            disabled={state?.Auth.loadingResetPassword}
            loading={state?.Auth.loadingResetPassword}
          />
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
