import Button from "../../Button"

const ActivateAccountComponent = ({activateAccount}:{activateAccount:() => void}) => {
    return(
         <div className="w-full h-4/6 mt-6 flex justify-center items-center">
            <div className="flex flex-col justify-center items-center space-y-6">
                <span>
                    <svg width="30" height="38" viewBox="0 0 30 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.305 3.71L15 0.666667L28.695 3.71C29.0651 3.79227 29.3961 3.99828 29.6334 4.29401C29.8706 4.58974 29.9999 4.95753 30 5.33667V21.9817C29.9999 23.6279 29.5933 25.2486 28.8165 26.7C28.0396 28.1514 26.9164 29.3886 25.5467 30.3017L15 37.3333L4.45333 30.3017C3.08379 29.3887 1.96077 28.1518 1.18391 26.7007C0.407059 25.2497 0.000393927 23.6293 0 21.9833V5.33667C6.5063e-05 4.95753 0.129397 4.58974 0.366649 4.29401C0.603902 3.99828 0.934895 3.79227 1.305 3.71ZM3.33333 6.67333V21.9817C3.33335 23.0791 3.60429 24.1596 4.1221 25.1271C4.63991 26.0947 5.38858 26.9195 6.30167 27.5283L15 33.3283L23.6983 27.5283C24.6112 26.9197 25.3597 26.0952 25.8775 25.1279C26.3953 24.1606 26.6664 23.0805 26.6667 21.9833V6.67333L15 4.08333L3.33333 6.67333ZM15 17.3333C13.8949 17.3333 12.8351 16.8943 12.0537 16.1129C11.2723 15.3315 10.8333 14.2717 10.8333 13.1667C10.8333 12.0616 11.2723 11.0018 12.0537 10.2204C12.8351 9.43899 13.8949 9 15 9C16.1051 9 17.1649 9.43899 17.9463 10.2204C18.7277 11.0018 19.1667 12.0616 19.1667 13.1667C19.1667 14.2717 18.7277 15.3315 17.9463 16.1129C17.1649 16.8943 16.1051 17.3333 15 17.3333ZM7.545 25.6667C7.74703 23.8307 8.61947 22.1338 9.99513 20.9012C11.3708 19.6686 13.1529 18.987 15 18.987C16.8471 18.987 18.6292 19.6686 20.0049 20.9012C21.3805 22.1338 22.253 23.8307 22.455 25.6667H7.545Z" fill="#B1B1B1"/>
                    </svg>
                </span>
                <div className="text-center space-y-2">
                    <h3 className="font-bold text-hair">Nothing to see here for now</h3>
                    <p className="text-grey text-xs">Click the button below to learn more</p>
                </div>
                <div className="w-52">
                    <Button text="Activate Account" onClick={activateAccount} />
                </div>
            </div>
        </div>
    )
}

export default ActivateAccountComponent;