import { useEffect } from "react";
import { useContext } from "react";
import { getOrganization } from "../../Requests/OrganizationRequest";
import { getActiveOrganizationId, getTokenInfo } from "../../utils/helper";
import { Context } from "../../utils/store";

const BillingsComponent: React.FC = ({ children }) => {
  const { state, dispatch } = useContext(Context);

  const getname = () => {
    const user = getTokenInfo().user;
    if (user.enrollment.last_name && user.enrollment.first_name) {
      return `${user.enrollment.first_name} ${user.enrollment.last_name}`;
    }
    return user.email;
  };
  useEffect(() => {
    if (!state?.Organization.organization) {
      getOrganization(dispatch, getActiveOrganizationId() as string);
    }
    // eslint-disable-next-line
  }, [state?.Organization.organization]);

  return (
    <div className="max-w-xl">
      <h3 className="text-lg mt-6">Billing</h3>
      <p className="mt-8 text-hair font-thin">
        We charge 60 naira per calls, for Identity verification checks
      </p>

      <div className="mt-6 md:mt-10 bg-white p-6 rounded space-y-6 shadow-drop-down-shadow">
        <h3 className="text-sm text-black ">
          Proceed to your Banking app to fund your MyIdentikey wallet
        </h3>
        <div className="">
          <dl className="w-full flex justify-between border-b py-6 text-sm text-black">
            <dt>Account Number</dt>
            <dd>
              {state?.Organization.organization &&
                state.Organization.organization.bank_info &&
                state.Organization.organization.bank_info.account_number}
            </dd>
          </dl>
          <dl className="w-full flex justify-between border-b py-6 text-sm text-black">
            <dt>Bank Name</dt>
            <dd>
              {" "}
              {state?.Organization.organization &&
                state.Organization.organization.bank_info &&
                state.Organization.organization.bank_info.bank_name}
            </dd>
          </dl>
          <dl className="w-full flex justify-between py-6 text-sm text-black">
            <dt>Beneficiary Name</dt>
            <dd>MMyIdentikey({getname()})</dd>
          </dl>
        </div>
      </div>
      <p className="mt-8 text-hair text-sm font-thin">
        Are you making more than 10,000 calls? Contact our sales team
      </p>

      {children}
    </div>
  );
};

export default BillingsComponent;
