import PersonalInformationForm from "../PersonalInformationForm";
import CompanyInformationForm from "../CompannyInformationForm";

const SettingsGeneral = () => {

  return (
    <div className="General">
          <PersonalInformationForm />
          <div className="mt-16"></div>
          <CompanyInformationForm />
    </div>
  );
};

export default SettingsGeneral;
