import { useContext } from "react";
import ModalLayout from ".";
import { Context } from "../../utils/store";
import Button from "../Button";

const RemoveTeamMemberModal = ({closeDeleteDialog, removeUser}: {closeDeleteDialog: () => void; removeUser: () => void}) => {
    const {state}= useContext(Context)
    const handleRemoveUser = () => {
        removeUser();
    }
    return(
        <ModalLayout>
            <div className="max-w-md bg-white rounded shadow px-4 py-6 relative">
                <div className="header flex items-center justify-between">
                    <h3 className="text-sm text-black font-medium ">Remove User</h3>
                    <button onClick={closeDeleteDialog} className="absolute top-5 right-5">
                        <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 6.2225L14.2225 0L16 1.7775L9.7775 8L16 14.2225L14.2225 16L8 9.7775L1.7775 16L0 14.2225L6.2225 8L0 1.7775L1.7775 0L8 6.2225Z" fill="#101010"/>
                        </svg>
                    </button>
                </div>
                <div className="body mt-6 space-y-8">
                     <p className="font-light text-black text-sm">Are you sure you want to remove this user from the team? This action cannot be undone.</p>
                    <div className="w-full flex justify-end space-x-2">
                        <div className="w-full md:w-32">
                            <Button text="Cancel" bordered={true} onClick={closeDeleteDialog} disabled={state?.Organization.loadingDeleteTeamMember}/>
                        </div>
                        <div className="w-full md:w-32">
                            <Button text="Remove User" danger={true}  onClick={handleRemoveUser} loading={state?.Organization.loadingDeleteTeamMember} disabled={state?.Organization.loadingDeleteTeamMember}/>
                        </div>
                    </div>
                </div>
            </div>
        </ModalLayout>
    )
}

export default RemoveTeamMemberModal;