import { Transition } from "@headlessui/react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { getOrganization } from "../../../Requests/OrganizationRequest";
import { getActiveOrganizationId } from "../../../utils/helper";
import { Context } from "../../../utils/store";
import SectionLoader from "../../Loader/SectionLoader";
import UserDetailsModal from "../../modal/UserDetails";
import Table from "../table";
import ActivateAccountComponent from "./ActivateAccountComponent";
import VerificationHeadingComponent from "./VerificationHeadingComponent";

const VerificationMain = () => {
  const { state, dispatch } = useContext(Context);
  const [showUserModal, setUserModal] = useState(false);
  const organizationId= getActiveOrganizationId() as string;
  const history= useHistory()

  const toggleUserDetailsModal = () => {
    setUserModal(!showUserModal);
  };

  useEffect(()=>{
    getOrganization(dispatch, organizationId)
    // eslint-disable-next-line
  },[]);

  const _redirectToActivation=()=>{
    history.push("/activation/get-started")
  }

  return (
    <>
      <main className="w-full h-full relative overflow-y-auto ml-0 md:ml-[20%] lg:ml-[16.666667%] pt-20 md:pt-24 px-4 md:px-12">
        <VerificationHeadingComponent />
        {state?.Organization.loadingOrganization ? (
          <SectionLoader />
        ) : (
          <>
            {state?.Organization.organization && state.Organization.organization.approved_at ? (
              <Table handleClick={toggleUserDetailsModal} />
            ) : (
              <ActivateAccountComponent
                activateAccount={_redirectToActivation}
              />
            )}
          </>
        )}
      </main>
      <Transition
        className="z-50"
        show={showUserModal}
        enter="transition duration-300 ease-in"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition duration-300 ease-out"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <UserDetailsModal closeModal={toggleUserDetailsModal} />
      </Transition>
    </>
  );
};

export default VerificationMain;
