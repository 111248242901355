import React from "react";
import { IAlert } from "../../utils/interfaces";

export const AlertMeesage: React.FC<IAlert> = ({ message, type }) => {
  return message ? (
    <div
      className={`${
        type === "success"
          ? "bg-green-100 text-green-500"
          : type === "warning"
          ? "bg-yellow-100 text-yellow-500"
          : "bg-red-100 text-red-500"
      } rounded w-full py-3 text-sm my-4 px-4 text-center capitalize`}
    >
      {Array.isArray(message)
        ? message.map((m, idx) => {
            return <p key={idx}>{m}</p>;
          })
        : message}
    </div>
  ) : null;
};
