export const LOGIN = "LOGIN";
export const REGISTER="REGISTER";
export const VERIFY_ACCOUNT="VERIFY_ACCOUNT";
export const FORGOT_PASSWORD="FORGOT_PASSWORD";
export const RESET_PASSWORD="RESET_PASSWORD";
export const CREATE_ORGANIZATION="CREATE_ORGANIZATION";
export const FETCH_ORGANIZATION="FETCH_ORGANIZATION";
export const FETCH_USER="FETCH_USER";
export const UPDATING_USER="UPDATING_USER";
export const FETCH_ORGANIZATIONS="FETCH_ORGANIZATIONS";
export const UPDATE_ORGANIZATION="UPDATE_ORGANIZATION";
export const FETCH_TEAM_MEMBERS="FETCH_TEAM_MEMBERS";
export const CREATE_TEAM_MEMBER="CREATE_TEAM_MEMBER";
export const CHANGE_PASSWORD="CHANGE_PASSWORD";
export const GET_COUNTRIES="GET_COUNTRIES";
export const GET_VERIFICATION_RANGES="GET_VERIFICATION_RANGES";
export const GET_BUSINESS_TYPES="GET_BUSINESS_TYPES";
export const ADD_TOAST="ADD_TOAST";
export const REMOVE_TOAST="REMOVE_TOAST";
export const DELETE_ORGANIZATION_USER="DELETE_ORGANIZATION_USER";

