import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Button from "../Button"
import TextInput from "../input/TextInput"
import { IFormValues } from "../../utils/interfaces";
import { useContext } from "react";
import { Context } from "../../utils/store";
import { useEffect } from "react";
import { updateUser } from "../../Requests/AuthRequest";

const schema = yup.object().shape({
  email: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
});


const PersonalInformationForm = () => {
    const { register, handleSubmit, setValue, formState:{ errors } } = useForm<IFormValues>({
        resolver: yupResolver(schema)
    });
    const {state, dispatch}= useContext(Context)

    const onSubmit:SubmitHandler<IFormValues> = async (data) =>{
      const userId= state?.Auth.user && state.Auth.user.id
      await updateUser(dispatch, userId as string, {first_name:data.firstName, last_name:data.lastName, email:data.email})
    }

    
    useEffect(()=>{
      if(state?.Auth && state.Auth.user){
        setValue("firstName", (state.Auth.user && state.Auth.user.enrollment.first_name) || "")
        setValue("lastName", (state.Auth.user && state.Auth.user.enrollment.last_name) ||"")
        setValue("email", (state.Auth.user && state.Auth.user.email)||"")
        // setValue("role", state.Auth.user && state.Auth.user.organizations[0])
      }
      // eslint-disable-next-line
    },[state?.Auth.user])

    return(
            <form className="space-y-12 w-full" onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-7">
                    <h4 className="text-sm font-medium text-black">Personal Information</h4>
                    <div className="rounded-md">
                        <div className="flex w-full">
                            <div className="w-1/2 relative">
                                <TextInput first={true} half={true} error={errors?.firstName?.message} register={register} second={false} last={false} id="first-name"  label="firstName" name="firstName" type="text" required={true} placeholder="First Name" />
                            </div>
                            <div className="w-1/2 relative">
                                <TextInput first={false} error={errors.lastName?.message} register={register} second={true} last={false}  id="last-name"  label="lastName" name="lastName" type="text" required={true} placeholder="Last Name" />
                            </div>
                        </div>
                        <div className="relative">
                            <TextInput first={false} second={false} error={errors.email?.message} register={register} last={false}  id="email" label="email" name="email" type="email" required={true} placeholder="Email Address" />
                        </div>
                        {/* TODO: uncomment below code to add role */}
                        <div className="relative">
                        <TextInput first={false} second={false} error={errors.role?.message} register={register} last={false}  id="role" label="role" name="role" type="text" required={true} placeholder="role" disabled/>
                        </div>
                    </div>
                </div>
                <div className="flex space-x-8 mt-16">
                    <div className="w-full md:w-40">
                        <Button text="Save" onClick={()=>handleSubmit(onSubmit)} loading={state?.Auth.loadingUpdatingUser || state?.Auth.loadingUser}  disabled={state?.Auth.loadingUpdatingUser || state?.Auth.loadingUser}  />
                    </div>
                </div>
            </form>
    )
}

export default PersonalInformationForm