const SectionLoader = () => {
  return(
      <div className="w-full h-full flex justify-center items-center overflow-y-auto space-y-10">
          <div className="max-w-lg w-40 py-6 bg-white rounded flex justify-center items-center animate-pulse">
              <span>
                  <svg xmlns="http://www.w3.org/2000/svg"  style={{marginRight:"5px", background: "none", display: "block", shapeRendering: "auto", width:"50px", height:"50px"}} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                      <circle cx="50" cy="50" r="32" stroke-width="8" stroke='#00ACE6' stroke-dasharray="50.26548245743669 50.26548245743669" fill="none" stroke-linecap="round">
                      <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
                      </circle>
                  </svg>
              </span>
          </div>
      </div>
  )
}

export default SectionLoader