import { DEFAULT_ORG_ID, TOKEN_KEY } from "../constants";
import jwt_decode from "jwt-decode";
import {REMOVE_TOAST, ADD_TOAST} from "../actions"
import React from "react";
import { IFormValues } from "./interfaces";

export const getTokenInfo = () => {
  const data = localStorage.getItem(TOKEN_KEY);
  if (data) return JSON.parse(data as string);
  return { token: null, user: null };
};

export const isLoggedIn = (tokenInfo?: {
  token: null | string;
  user: null | { [key: string]: any };
}) => {
  const data = tokenInfo || getTokenInfo();
  if (data && data.token !== null) {
    const { exp } = jwt_decode<any>(data.token);
    const currentTime = Date.now().valueOf() / 1000;
    if (currentTime > exp) {
      localStorage.clear();
      return false;
    }
    return true;
  }
  return false;
};

export const hasOrganisation = (organsation: Record<string, any>[]) => {
  if (!organsation) return false;
  return organsation.length > 0;
};

export const setActiveOrganizaton = (
  organsations: Record<string, any>[],
  userId: string
) => {
  if (organsations.length === 0) return null;
  let org;
  org = organsations.find((org) => {
    return org.created_by === userId;
  });
  if (!org) {
    org = organsations[0];
  }
  localStorage.setItem(DEFAULT_ORG_ID, org.id);
};

export const getActiveOrganizationId = () => {
  return localStorage.getItem(DEFAULT_ORG_ID);
};

export const processFormData = (
  file: any,
  fileName: string,
  payload?: Record<string, any>
) => {
  const formData = new FormData();
  formData.append(fileName, file);
  if (payload && Object.keys(payload).length > 0) {
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });
  }
  return formData;
};

export const toast=(dispatch:React.Dispatch<any>, payload:{type:string, text:string})=>{
  dispatch({type:ADD_TOAST,payload});
}

export const deleteToast=(id:number, dispatch:React.Dispatch<any>)=>{
  dispatch({payload:id, type:REMOVE_TOAST});
}

export const generateToastId=()=>{
  return Math.floor((Math.random() * 100) + 1)
}

export const truncateText=(value:string, maxLength?:number)=>{
  const max=maxLength || 30;
  if (max === null) {
    return value;
  } else {
    if (value.length > max) {
      return value.substring(0, max) + " ...";
    }
    return value;
  }

}

export const defaultAuthErrorState:IFormValues={
  email:"",
  password:"",
  phone:""
}


export const defaultCompanyErrorState:IFormValues={
  company:"",
  email:"",
  address:"",
  location:"",
  phone:"",
  rcNumber:"",
  directorName:"",
  website:""
}

export function validateField(error:any, field:any):error is IFormValues{
  return field in error
}
export const _displayValidation=(field:any, Globalstate:Record<string, any>, prop:any, currentState :Record<string, any> ):string=>{
  if(Globalstate[currentState as any][prop] && typeof Globalstate[currentState as any][prop]?.error ==="object"  && validateField(Globalstate[currentState as any][prop].error, field) ) return Globalstate[currentState as any][prop]?.error[field as keyof IFormValues] as string
  return ""
}

export const _displayValidationStateless=(error:Record<string, any>, field:string)=>{
  if(error && typeof error==="object" && validateField(error, field)) return error[field as keyof IFormValues] as string
  return ""
}

export const mapProperty2Key=(key:string)=>{
  const keys:Record<string, any>={
    first_name:"FirstName",
    last_name:"lastName",
    name:"company",
    director_name:"directorName",
    rc_number:"rcNumber",
    new_password:"newPassword",
    old_password:"password"
  }
  if(Object.keys(keys).includes(key.toLowerCase())) return keys[key];
  return key
}