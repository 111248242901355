const SkeletonLoader = ({count}:{count?:number}) => {
  const counts = Array(count || 7).fill(count||7)
    return(
        <div className="w-full py-4 space-y-3">
        {
          counts.map((_,idx)=>{
           return <div  key={idx}className="w-full h-5 bg-gray-100 rounded animate-pulse" />
          })
        }
        </div>
    )
}

export default SkeletonLoader;