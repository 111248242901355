import React from "react";
import {
  FORGOT_PASSWORD,
  LOGIN,
  REGISTER,
  RESET_PASSWORD,
  VERIFY_ACCOUNT,
  FETCH_USER,
  UPDATING_USER,
} from "../actions";
import { TOKEN_KEY } from "../constants";
import { asyncActions } from "../utils/AsyncUtils";
import { IFormValues } from "../utils/interfaces";
import * as AUTHAPI from "./Apis/authAPI";
import { api } from "../utils/axios";
import { defaultAuthErrorState, getTokenInfo, toast } from "../utils/helper";
import { dispatchError, statelessErrorHandler } from "./dispatchError";

export const persistToken = (
  token: string,
  user: Record<string, any>
): Promise<unknown> => {
  return new Promise((resolve) =>
    resolve(localStorage.setItem(TOKEN_KEY, JSON.stringify({ token, user })))
  );
};

export const setTokenAuth = (token: string) => {
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const login = async (
  dispatch: React.Dispatch<any>,
  payload: IFormValues,
  cb: (data: Record<string, any>) => void
) => {
  dispatch(asyncActions(LOGIN).loading(true));
  dispatch(asyncActions(LOGIN).failure(false, defaultAuthErrorState));
  try {
    const { data } = await AUTHAPI.login(payload);
    const { token, user } = data;
    setTokenAuth(token);
    await persistToken(token, user);
    setTimeout(() => {
      dispatch(asyncActions(LOGIN).loading(false));
      cb(user);
    }, 3000);
    dispatch(asyncActions(LOGIN).success(user));
  } catch (e) {
    if (e && e.response) {
      dispatchError(dispatch, asyncActions, e, e.status, LOGIN, false, true);
    }
  }
};

export const signup = async (
  dispatch: React.Dispatch<any>,
  payload: IFormValues,
  cb?: (data: Record<string, any>) => void
) => {
  dispatch(asyncActions(REGISTER).loading(true));
  dispatch(asyncActions(REGISTER).failure(false, defaultAuthErrorState));

  try {
    const { data } = await AUTHAPI.register(payload);
    const { token, user } = data;
    setTokenAuth(token);
    await persistToken(token, user);
    cb && cb(user);
    dispatch(asyncActions(REGISTER).success(user));
    dispatch(asyncActions(REGISTER).loading(false));
  } catch (e) {
    if (e && e.response) {
      dispatchError(dispatch, asyncActions, e, e.status, REGISTER, false, true);
    }
  }
};

export const verifyAccount = async (
  dispatch: React.Dispatch<any>,
  token: { token: string },
  cb?: (data: Record<string, any>) => void
) => {
  dispatch(asyncActions(VERIFY_ACCOUNT).loading(true));
  dispatch(asyncActions(VERIFY_ACCOUNT).failure(false, null));

  try {
    const { status } = await AUTHAPI.verifyAccount(token);
    if (status === 200) {
      let userInfo = getTokenInfo();
      const { user, token } = userInfo;
      user.verified = true;
      await persistToken(token, user);
      cb && cb(user);
    }
    dispatch(asyncActions(VERIFY_ACCOUNT).loading(false));
    dispatch(asyncActions(VERIFY_ACCOUNT).failure(false, null));
  } catch (e) {
    if (e && e.response) {
      dispatchError(dispatch, asyncActions, e, e.status, VERIFY_ACCOUNT);
    }
  }
};

export const forgotPassword = async (
  dispatch: React.Dispatch<any>,
  payload: IFormValues,
  cb?: () => void
) => {
  dispatch(asyncActions(FORGOT_PASSWORD).loading(true));
  dispatch(asyncActions(FORGOT_PASSWORD).failure(false, {email:""}));

  try {
    const { status } = await AUTHAPI.forgotPassword(payload);
    if (status === 200) {
      cb && cb();
    }
    dispatch(asyncActions(FORGOT_PASSWORD).loading(false));
  } catch (e) {
    if (e && e.response) {
      dispatchError(dispatch, asyncActions, e, e.status, FORGOT_PASSWORD, false, true);
    }
  }
};

export const resetPassword = async (
  dispatch: React.Dispatch<any>,
  payload: IFormValues,
  cb?: () => void
) => {
  dispatch(asyncActions(RESET_PASSWORD).loading(true));
  dispatch(asyncActions(RESET_PASSWORD).failure(false, {email:"", token:""}));

  try {
    const { status } = await AUTHAPI.resetPassword(payload);
    if (status === 200) {
      cb && cb();
    }
    dispatch(asyncActions(RESET_PASSWORD).loading(false));
  } catch (e) {
    if (e && e.response) {
      dispatchError(dispatch, asyncActions, e, e.status, RESET_PASSWORD, false, true);
    }
  }
};

export const changePassword = async (
  payload: Record<string, any>,
  onDone?: () => void,
  onError?: (err: string | string[]) => void
) => {
  try {
    const { status } = await AUTHAPI.changePassword(payload);
    if (status === 200) {
      onDone && onDone();
    }
  } catch (e) {
    if (e && e.response) {
      if (onError) {
        statelessErrorHandler(onError, e, true);
      }
    }
  }
};

export const getLoggedInUser = async (dispatch: React.Dispatch<any>) => {
  dispatch(asyncActions(FETCH_USER).loading(true));
  dispatch(asyncActions(FETCH_USER).failure(false, null));

  try {
    const { status, data } = await AUTHAPI.getLoggedInUser();
    if (status === 200) {
      dispatch(asyncActions(FETCH_USER).success(data.user));
    }
    dispatch(asyncActions(FETCH_USER).loading(false));
  } catch (e) {
    if (e && e.response) {
      dispatchError(dispatch, asyncActions, e, e.status, FETCH_USER);
    }
  }
};

export const updateUser = async (
  dispatch: React.Dispatch<any>,
  id: string,
  payload: Record<string, any>
) => {
  dispatch(asyncActions(UPDATING_USER).loading(true));
  dispatch(asyncActions(UPDATING_USER).failure(false, null));

  try {
    const { status, data } = await AUTHAPI.updateUser(payload, id);
    if (status === 200) {
      toast(dispatch, { type: "success", text: data.message });
      dispatch(asyncActions(UPDATING_USER).success(data.data));
    }
    dispatch(asyncActions(UPDATING_USER).loading(false));
  } catch (e) {
    if (e && e.response) {
      dispatchError(dispatch, asyncActions, e, e.status, UPDATING_USER);
    }
  }
};

export const loginOut = (history: any) => {
  history.push("/login");
  localStorage.clear();
};
